import React, { useState, useEffect } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import { styled as muiStyled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useReliqueryContract, useTokenContract, useSingleSidedLiquidityContract } from '../../hooks/useContract'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { ArrowUpCircle } from 'react-feather'
import InputAdornment from '@mui/material/InputAdornment'
import InputBase from '@mui/material/InputBase'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import { Stack } from '@mui/material'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from '../../hooks'
import { POOL_ID_RELIQUERY, RELIQUERY_ADDRESS, SINGLE_SIDED_LIQUIDITY } from '../../constants'

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})

const BootstrapDialog = muiStyled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    background: '#181818'
  },
  '& .MuiDialogActions-root': {
    background: '#181818'
  },
  '& .MuiDialog-paper': {
    background: '#181818',
    border: '1px solid #2E2E2E',
    boxShadow: '0px 0px 63.5px 2px #49494940',
    borderRadius: '32px',
    padding: '20px',
    minWidth: '400px'
  }
}))
const CssTextField = muiStyled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    background: '#333333',
    borderRadius: '57px',
    color: '#EAEAEA',
    '& .MuiInputBase-input': {
      color: '#EAEAEA',
      fontFamily: 'Inter',
      fontSize: '14px',
      height: '29px',
      fontWeight: 400
    },
    '& fieldset': {
      borderColor: '#2E2E2E'
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C'
    }
  }
})
const BootstrapInput = muiStyled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#181818',
    border: '1px solid #181818',
    fontSize: 14,
    borderRadius: '32px',
    padding: '5px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Inter',
    '&:focus': {
      borderColor: '#181818',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '&:active': {
      borderColor: '#181818',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))
const ModalTitle = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const InfoLabel = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 23.87px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #eaeaea;
  margin: 0px;
`
const StyledButton = styled.button`
  background: #ffffff;
  padding: 10px;
  border-radius: 44px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  border: none;
  cursor: pointer;
`
const SubTitleText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #b7b7b7;
  text-align: center;
  margin: 0px;
`
interface InputTokens {
  name: string
  symbol: string
  decimals: string
  address: string
  allowence: string
  balance: string
  isSingleSided: boolean
}
export interface SimpleDialogProps {
  open: boolean
  selectedValue?: string
  onClose?: () => void
  inputTokens: InputTokens[]
  isExistingPosition?: boolean
  relicId?: string
  isApproved?: boolean
}
export default function DepositFarmModal(props: SimpleDialogProps) {
  const { onClose, open, inputTokens, isExistingPosition, relicId } = props
  const reliqueryContract = useReliqueryContract()
  const singleSidedLiquidityContract = useSingleSidedLiquidityContract()
  const { account } = useActiveWeb3React()
  const [selectedToken, setSelectedToken] = useState<InputTokens | null>(null)
  const [txProcessing, setTxProcessing] = useState(false)
  const [txSuccess, setTxSuccess] = useState(false)
  const [isSlChimpApproved, setIsSlChimpApproved] = useState(false)
  const tokenContract = useTokenContract(selectedToken?.address, true)
  const [amount, setAmount] = useState('0')
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleTokenSelect = (event: any) => {
    const getToken = inputTokens.find(token => token.address === event.target.value)
    if (getToken) {
      setSelectedToken(getToken)
    }
  }
  const handleDeposit = async () => {
    try {
      if (reliqueryContract && selectedToken) {
        setTxProcessing(true)
        if (selectedToken.isSingleSided) {
          if (singleSidedLiquidityContract) {
            const amountInWei = new BigNumber(amount).multipliedBy(10 ** parseFloat(selectedToken.decimals)).toString()
            const toToken = inputTokens.find(token => token.isSingleSided && token.address !== selectedToken.address)
            const lpToken = inputTokens.find(token => !token.isSingleSided)
            console.log(
              'check',
              account,
              selectedToken.address,
              amountInWei,
              lpToken?.address,
              toToken?.address,
              '1',
              [selectedToken.address, toToken?.address],
              true,
              isExistingPosition ? false : true,
              POOL_ID_RELIQUERY,
              relicId ? relicId : 0
            )
            const tx = await singleSidedLiquidityContract.poolLiquidityUniswapV2(
              account,
              selectedToken.address,
              amountInWei,
              lpToken?.address,
              toToken?.address,
              '1',
              [selectedToken.address, toToken?.address],
              true,
              isExistingPosition ? false : true,
              POOL_ID_RELIQUERY,
              relicId ? relicId : 0
            )
            await tx.wait()
          }
        } else {
          const amountInWei = new BigNumber(amount).multipliedBy(10 ** parseFloat(selectedToken.decimals)).toString()
          if (isExistingPosition) {
            const tx = await reliqueryContract.deposit(amountInWei, relicId)
            await tx.wait()
          } else {
            const tx = await reliqueryContract.createRelicAndDeposit(account, POOL_ID_RELIQUERY, amountInWei)
            await tx.wait()
          }
        }
        setTxProcessing(false)
        setTxSuccess(true)
      }
    } catch (error) {
      console.log('error', error)
      setTxProcessing(false)
    }
  }
  const handleApprove = async () => {
    try {
      if (tokenContract && selectedToken) {
        setTxProcessing(true)
        const approveTx = await tokenContract.approve(
          selectedToken.isSingleSided ? SINGLE_SIDED_LIQUIDITY : RELIQUERY_ADDRESS,
          MaxUint256
        )
        await approveTx.wait()
        const allowenceAfter = await tokenContract.allowance(
          account,
          selectedToken.isSingleSided ? SINGLE_SIDED_LIQUIDITY : RELIQUERY_ADDRESS
        )
        console.log('allowenceAfter', allowenceAfter.toString())
        setSelectedToken({
          ...selectedToken,
          allowence: new BigNumber(allowenceAfter).dividedBy(10 ** parseFloat(selectedToken.decimals)).toString()
        })
        setTxProcessing(false)
      }
    } catch (error) {
      console.log('error', error)
      setTxProcessing(false)
    }
  }
  const handleApproveSlChimp = async () => {
    try {
      if (reliqueryContract) {
        setTxProcessing(true)
        const tx = await reliqueryContract.approve(SINGLE_SIDED_LIQUIDITY, relicId)
        await tx.wait()
        setIsSlChimpApproved(true)
        setTxProcessing(false)
      }
    } catch (error) {
      console.log('error', error)
      setTxProcessing(false)
    }
  }
  useEffect(() => {
    if (selectedToken === null && inputTokens.length > 0) {
      setSelectedToken(inputTokens[0])
    }
  }, [inputTokens, selectedToken])
  useEffect(() => {
    setIsSlChimpApproved(props.isApproved || false)
  }, [props.isApproved])
  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ModalTitle>Deposit Tokens</ModalTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {txSuccess ? (
        <Stack spacing={4} sx={{ marginTop: '30px' }} justifyContent="center" alignItems="center">
          <ArrowUpCircle strokeWidth={0.5} size={90} color="#fff" />

          <SubTitleText style={{ textAlign: 'center', fontSize: '16px' }}>Transcation Successfull</SubTitleText>
          <StyledButton style={{ width: '100%' }} onClick={handleClose}>
            Close
          </StyledButton>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ marginTop: '30px' }}>
          <div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '0px 10px 8px 8px' }}
            >
              <InfoLabel>Deposit</InfoLabel>
              {selectedToken && (
                <InfoLabel>
                  Balance: {parseFloat(selectedToken?.balance).toFixed(2)} {selectedToken?.symbol}
                </InfoLabel>
              )}
            </Stack>
            <CssTextField
              size="small"
              id="custom-css-outlined-input"
              fullWidth
              type="number"
              value={amount}
              onChange={event => setAmount(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={selectedToken?.address}
                      onChange={handleTokenSelect}
                      input={<BootstrapInput />}
                    >
                      {inputTokens?.map(token => (
                        <MenuItem key={token.address} value={token.address}>
                          {token.symbol}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                )
              }}
            />
          </div>
          {selectedToken && new BigNumber(selectedToken?.allowence).isGreaterThanOrEqualTo(amount) ? (
            selectedToken.isSingleSided ? (
              isSlChimpApproved ? (
                <StyledButton disabled={txProcessing} onClick={handleDeposit}>
                  {txProcessing ? 'Processing...' : 'Deposit'}
                </StyledButton>
              ) : (
                <StyledButton disabled={txProcessing} onClick={handleApproveSlChimp}>
                  {txProcessing ? 'Processing...' : 'Approve slchimp'}
                </StyledButton>
              )
            ) : (
              <StyledButton disabled={txProcessing} onClick={handleDeposit}>
                {txProcessing ? 'Processing...' : 'Deposit'}
              </StyledButton>
            )
          ) : (
            <StyledButton disabled={txProcessing} onClick={handleApprove}>
              {txProcessing ? 'Processing...' : 'Approve'}
            </StyledButton>
          )}
        </Stack>
      )}
    </BootstrapDialog>
  )
}
